<template>
  <div class="Chinabox">
    <div class="img_header"><img src="../../img/headerimg.png" alt="" /></div>
    <div class="fs30">中国业主篇</div>
    <div class="headline">- 前往苏梅 -</div>
    <div class="flex">
      <fieldset class="height_change">
        <legend>
          <img src="../../img/aircraft.png" alt="" />
        </legend>
        <div>飞机</div>
        <div class="shengxian"></div>
        <div>
          目前国内已开通直飞苏梅航线：西安、重庆、成都、昆明，转机均在泰国曼谷，曼谷直飞苏梅1小时。中国游客可以申请落地签证。
        </div>
      </fieldset>
      <fieldset class="height_change">
        <legend>
          <img src="../../img/steamer.png" alt="" />
        </legend>
        <div>轮渡</div>
        <div class="shengxian"></div>
        <div class="content">
          素叻他尼有很多轮渡服务直接到达苏梅岛。包括快船和夜间慢船（需要6~7个小时）。部分快艇和轮渡还会到帕岸岛和涛岛。
        </div>
      </fieldset>
    </div>
    <div class="headline">- 吃喝玩乐在苏梅 -</div>
    <div class="fs18">
      苏梅岛作为度假天堂，吃喝玩乐必不可少，同时非常受游客欢迎。关于苏梅岛的特色旅游介绍可以通过吃喝玩乐在苏梅|2020年最强苏梅岛旅游攻略详细了解。
    </div>
    <div class="chinaimgbox">
      <div v-for="(item, index) in imgarr" :key="index">
        <div><img :src="item.imgurl" alt="" /></div>
        <div class="matop">{{ item.imgname }}</div>
      </div>
    </div>
    <div class="headline">- 苏梅岛生活成本 -</div>
    <div class="rollbox">
      <div class="nb">
        <div class="w17">
          <img src="../../img/Shoppingbags_icon.png" alt="" /><span> 购物</span>
        </div>
        <hr />

        <div class="dbug">
          苏梅岛气候温暖宜居，通常穿着夏季常衣物即可，泰国有很多本土品牌，价格低廉质量好，适合喜爱舒适穿着的客户群体购买，岛上的购物选择也很多，如Central
          Festival一直以来都是岛上最受欢迎的购物中心，此外岛上还有大量的商店和国际超市。
        </div>
      </div>
      <div class="nb">
        <div class="w17">
          <img src="../../img/car_icon.png" alt="" /><span> 出行</span>
        </div>
        <hr />
        <div class="dbug">
          苏梅岛上的交通工具方式主要以汽车和摩托车为主，除加油外基本没有其他费用，出行灵活自由。苏梅岛有专属的打车软件Navigo，功能和操作与Uber相似。价格比出租车便宜，有泰语和英语两种语言，可提前预约时间和地点，还可以选择车型。
        </div>
      </div>
      <div class="nb">
        <div class="w17">
          <img src="../../img/fork-spoon_icon.png" alt="" /><span> 餐饮</span>
        </div>
        <hr />
        <div class="dbug">
          苏梅岛汇集了世界各国美食，如泰餐、中餐、西餐、日餐、韩餐、印餐等，有众多值得打卡的高品质餐厅。泰国被不少国人评为“物价最低”的国家，按照一家三口每天摄入足量肉、蛋、蔬菜、水果并且每个周末都出去玩的生活标准，平均每人每月消费约1420元人民币。
        </div>
      </div>
    </div>
    <div class="headline">- 全球换住 -</div>
    <div class="fs18">
      长租式住宅托管服务，租赁情况实时可查，并可换住全球100多个目的地城市。
    </div>
    <div class="w380">
      <img src="../../img/app_image.png" alt="" />
    </div>
    <div class="headline man">- 养老签证及医疗设施 -</div>
    <div class="w100"><img src="../../img/yanglao_image.png" alt="" /></div>
    <div class="textold">
      泰国凭借优美的自然环境、发达的医疗水平及低廉的生活成本，成为了最受青睐的养老国家之一。泰国法律规定年满50岁的外国人即可申请长时间居留泰国的养老签证，养老签证在有效期内可允许持有者自由多次出入泰国。从羽翼苏梅出发10分钟车程内可到达泰国国际医院、Bondon国际医院和曼谷国际医院，苏梅岛是世界最佳养老宜居岛屿之一。
    </div>
    <div class="headline">- 长期租赁 -</div>
    <div class="flex">
      <fieldset>
        <legend>模式1</legend>
        <div>不租赁</div>
        <div class="shengxian"></div>
        <div>客户自行管理房产</div>
      </fieldset>
      <fieldset>
        <legend>模式2</legend>
        <div>长期租赁</div>
        <div class="shengxian"></div>
        <div>
          由租赁品牌接管，需客户单独购买布草包
        </div>
      </fieldset>
      <fieldset>
        <legend>模式3</legend>
        <div>每年享14天免费自住权</div>
        <div class="shengxian"></div>
        <div>
          每年享14天免费自住权
        </div>
      </fieldset>
    </div>


    <div class="headline">- 长租品牌 -</div>
    <div class="flex">
      <fieldset class="border">
        <legend></legend>
        <div><img src="../../img/占位.jpg" alt=""></div>
        <!-- <div class="shengxian"></div> -->
        <div></div>
      </fieldset>
      <fieldset class="border">
        <legend></legend>
        <div><img src="../../img/长租品牌logo.png" alt=""></div>
        <div class="shengxian"></div>
        <div>
          温德姆花园酒店
        </div>
      </fieldset>
      <fieldset class="border">
        <legend></legend>
        <div><img src="../../img/占位.jpg" alt=""></div>
        <!-- <div class="shengxian"></div> -->
        <div>
          
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

      imgarr: [
        {
          imgurl: require("../../img/1.png"),
          imgname: "热门海滩",
        },
        {
          imgurl: require("../../img/2.png"),
          imgname: "旅游景点",
        },
        {
          imgurl: require("../../img/3.png"),
          imgname: "特色美食",
        },
        {
          imgurl: require("../../img/4.png"),
          imgname: "网红餐厅",
        },
        {
          imgurl: require("../../img/5.png"),
          imgname: "特色娱乐",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.height_change {
  height: 250px;
}

fieldset div img {
  width: 100%;
}

.textold {
  margin: 0.4rem auto;
  font-size: 0.17rem;
  line-height: 0.3rem;
  color: #666666;
}

.w100 {
  width: 100%;

}

.border {
  border: none;
}

.w380 {
  width: 3.8rem;
  height: 4.1rem;
  margin: 0.4rem auto;

  img {
    width: 100%;
  }
}

.rollbox {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-top: 0.8rem;
}

.dbug {
  height: 3rem;
  line-height: 0.25rem;
  font-weight: 300;
  font-size: 0.15rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  letter-spacing: 0.01rem;
}

.w17 {
  height: 0.3rem;
  display: flex;
  align-items: center;

  span {
    font-size: 0.15rem;
    padding-left: 0.2rem;
  }
}

.nb {
  margin-right: 0.1rem;
  width: 33%;
}

.chinaimgbox {
  width: 100%;
  margin: 0 auto;
  display: flex;
  font-size: 0.2rem;
  text-align: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}

.chinaimgbox>div {
  width: 1.82rem;
  margin-bottom: 0.15rem;
  margin-right: 0.4rem;
}

.chinaimgbox img {
  width: 100%;
}

.fs18 {
  font-size: 0.18rem;
  color: #666666;
  letter-spacing: 0.03rem;
  text-indent: 0.02rem;
  padding: 0 0.1rem;
  line-height: 0.3rem;
}

.man {
  margin-top: 100px !important;
}

.flex {
  width: 100%;
  display: flex;
  margin: 0 auto;
  margin-bottom: 0.5rem;
}

.headline {
  font-size: 0.26rem;
  text-align: center;
  font-weight: 700;
  margin: 0.4rem 0;
}

legend {
  color: #cfc6ba;
  font-size: 0.1rem;
  text-align: center;
}

.Chinabox {
  width: 8rem;
  height: 100%;
  margin: 0 auto;
  // background-color: antiquewhite;
}

.img_header {
  height: 0.8rem;
  width: 4.26rem;
  margin: 0 auto;

  img {
    width: 100%;
  }
}

.fs30 {
  margin-top: 0.8rem;
  font-size: 0.3rem;
  text-align: center;
}

.fs17 {
  margin-top: 0.3rem;
  font-size: 0.17rem;
  text-align: center;
  font-weight: 300;
}

fieldset :nth-child(2) {
  font-size: 0.2rem;
  text-align: center;
  margin-bottom: 0.1rem;
  margin-top: 0.1rem;
}

fieldset :nth-child(4) {
  font-size: 0.16rem;
  line-height: 0.4rem;
  text-align: center;
  color: #666666;
  padding: 0 0.3rem 0.5rem 0.3rem;
  font-weight: 300;
}

fieldset {
  border: 0.01rem solid #cfc6ba;
  width: 3.9rem;
  height: 1.5rem;
  margin-right: 0.1rem;
}

fieldset legend {
  width: 0.5rem;
  margin: 0 auto;
}

fieldset legend img {
  width: 100%;
}

.shengxian {
  width: 0.4rem;
  margin: 0 auto;
  border-top: 0.02rem solid #cfc6ba;
  margin-bottom: 0.1rem;
}

.matop {
  margin-top: 0.2rem;
}
</style>